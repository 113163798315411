import React from "react";
import {Box, Divider, List, Typography, useDataGrid} from "@pankod/refine-mui";
import {useList} from "@pankod/refine-core";

export const DashboardPage: React.FC = () => {
    const {data} = useList({resource: "subscriptions"})
    // @ts-ignore
    const emails = data?.data?.data


    return (
        <List title={<Typography variant="h4">Abone Olanlar</Typography>}>
            <Box>
                {
                    emails?.map((email: any) => <><Box p={1}>
                        {email?.email}
                    </Box>
                        <Divider sx={{width: '100%', backgroundColor: '#999999', mt: 1.5}}/>

                    </>)
                }
            </Box>
        </List>
    );
};
