import React, {useState} from "react";
import {HttpError, IResourceComponentsProps, useCreate, useCustomMutation} from "@pankod/refine-core";
import {useForm, UseModalFormReturnType,} from "@pankod/refine-react-hook-form";
import {
    Autocomplete,
    Box,
    Button,
    Create,
    Drawer,
    FormControl,
    FormHelperText,
    FormLabel, IconButton,
    OutlinedInput,
    SaveButton,
    Stack, TextField,
    Typography
} from "@pankod/refine-mui";

import CloseIcon from "@mui/icons-material/Close";

export const ProductCreate: React.FC<IResourceComponentsProps> = () => {
    const [inputs, setInputs] = useState<any>({})

    const [features, setFeatures] = useState<any[]>([]);

    const {
        watch,
        saveButtonProps,
        refineCore: {onFinish},
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm();

    const handleChangeValue = (value: any, type: string) => {
        setInputs({...inputs, [type]: value})
    }
    const {mutate} = useCreate();
    function useCreateMutate() {
        mutate({
                resource: "products",
                values: {
                    "image_url" : inputs?.image_url,
                    "color" : inputs?.color,
                    "title": inputs?.title,
                    "description": inputs?.description,
                    "price": Number(inputs?.price),
                    "discounted_price": Number(inputs?.discounted_price),
                    "status": inputs?.status,
                    "stock": Number(inputs?.stock),
                    "brand": inputs?.brand ,
                    "category": inputs?.category,
                    "special_category": inputs?.special_category,
                },
            },
            {
                onError: (error, variables, context) => {
                    alert(error.message)

                },
                onSuccess: async (data, variables, context) => {
                    setInputs({});
                    setFeatures([]);
                }
            });
    }

    function handleRemove(index: number){
        setFeatures(features.filter((feature, key) => key !== index))
    }
    return (
        <>
            <Create
                resource="subscription-packages"
                footerButtonProps={{sx: {display: 'none'}}}
                title={"Ürün Ekle"}
            >
                <Stack>
                    <Box
                        paddingX="50px"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            paddingX: {
                                xs: 1,
                                md: 6,
                            },
                        }}
                    >
                        <form className="form" onSubmit={handleSubmit(onFinish)}>
                            <Stack gap="10px" marginTop="10px">

                                <FormControl>
                                    <FormLabel>
                                        Ürün Başlığı
                                    </FormLabel>
                                    <OutlinedInput
                                        id="title"
                                        onChange={(e) => handleChangeValue(e.target.value, "title")}

                                        style={{height: "40px"}}
                                    />

                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Ürün Resim URL
                                    </FormLabel>
                                    <OutlinedInput
                                        id="image_url"
                                        onChange={(e) => handleChangeValue(e.target.value, "image_url")}

                                        style={{height: "40px"}}
                                    />

                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Ürün Rengi
                                    </FormLabel>
                                    <OutlinedInput
                                        id="color"
                                        onChange={(e) => handleChangeValue(e.target.value, "color")}

                                        style={{height: "40px"}}
                                    />

                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Ürün Açıklaması
                                    </FormLabel>
                                    <OutlinedInput
                                        id="description"
                                        onChange={(e) => handleChangeValue(e.target.value, "description")}
                                        style={{height: "40px"}}
                                    />

                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Ürün Durumu
                                    </FormLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="brand"
                                        options={["zero", "secondhand", "other"]}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, value) =>
                                            handleChangeValue(value, "status")
                                        }
                                        sx={{height: "60px"}}

                                        renderInput={(params) => <TextField {...params} label="" />}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Model
                                    </FormLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="brand"
                                        options={["ps3", "ps4", "ps5", "psvr", "xboxseriess", "xboxseriesx", "nintendo", "other"]}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, value) =>
                                            handleChangeValue(value, "brand")
                                        }
                                        sx={{height: "60px"}}

                                        renderInput={(params) => <TextField {...params} label="" />}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Kategori
                                    </FormLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="category"
                                        options={["console", "controller", "game", "other"]}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, value) =>
                                            handleChangeValue(value, "category")
                                        }
                                        sx={{height: "60px"}}

                                        renderInput={(params) => <TextField {...params} label="" />}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Özel Kategori
                                    </FormLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="special_category"
                                        options={["top10", "discount", "featured", "new", "null"]}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, value) =>
                                            handleChangeValue(value, "special_category")
                                        }
                                        sx={{height: "60px"}}

                                        renderInput={(params) => <TextField {...params} label="" />}
                                    />
                                </FormControl>
                                <FormControl>

                                    <FormLabel>
                                        İndirimsiz Fiyat
                                    </FormLabel>
                                    <OutlinedInput
                                        placeholder="Ürün İndirimliyse Giriniz."
                                        onChange={(e) => handleChangeValue(e.target.value, "discounted_price")}
                                        style={{height: "40px"}}

                                    />

                                </FormControl>
                                <FormControl>

                                    <FormLabel>
                                        Fiyat
                                    </FormLabel>
                                    <OutlinedInput
                                        onChange={(e) => handleChangeValue(e.target.value, "price")}
                                        style={{height: "40px"}}

                                    />

                                </FormControl>
                                <FormControl>

                                    <FormLabel>
                                        Stok Adedi
                                    </FormLabel>
                                    <OutlinedInput
                                        onChange={(e) => handleChangeValue(e.target.value, "stock")}
                                        style={{height: "40px"}}

                                    />

                                </FormControl>
                                <SaveButton onClick={() => {
                                    useCreateMutate();

                                }}/>
                            </Stack>
                        </form>
                    </Box>
                </Stack>
            </Create>
        </>
    )
}
