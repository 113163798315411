import * as React from "react";
import {AuthPage as MUIAuthPage, AuthProps, Box, Grid, Typography} from "@pankod/refine-mui";

import {useRouterContext} from "@pankod/refine-core";

const authWrapperProps = {
    style: {

        backgroundColor: '#000'
    },
};

const renderAuthContent = (content: React.ReactNode) => {
    const {Link} = useRouterContext();

    return (
        <div
            style={{
                margin: "auto",
            }}
        >
            {/*  <Link to="/">
                <img
                    src="/images/fine-foods-login.svg"
                    alt="fineFoods Logo"
                    style={{width: "100%", marginBottom: 26}}
                />
            </Link>
            */}
            <Typography fontSize={40} align="center" fontWeight={900}>YÖNETİM GİRİŞİ</Typography>

            {content}
        </div>
    );
};

const renderAuthContent2 = (content: React.ReactNode) => {
    const {Link} = useRouterContext();

    return (
        <div
            style={{
                margin: "auto",
            }}
        >
            <Typography fontSize={40} align="center" fontWeight={900}>YÖNETİCİ GİRİŞİ</Typography>
            {content}
        </div>
    );
};

export const AuthPage: React.FC<AuthProps> = ({type, formProps}) => {
    return (
        <>
            <Grid item container>
                <Grid item xs={12}>
                    <MUIAuthPage
                        type={type}
                        wrapperProps={authWrapperProps}
                        renderContent={renderAuthContent2}
                        formProps={formProps}
                    />
                </Grid>


            </Grid>
        </>
    );
};
