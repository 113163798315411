import React from "react";
import {IResourceComponentsProps, useDelete} from "@pankod/refine-core";
import {DataGrid,  GridActionsCellItem, GridColumns, List, Typography, useDataGrid} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";

export const MessageList: React.FC<IResourceComponentsProps> = () => {
    const {dataGridProps, tableQueryResult} = useDataGrid<any>();
    const messageData = tableQueryResult?.data?.data
    // @ts-ignore
    const messages = messageData?.data ?? []
    console.log(messages)

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "id",
                headerName: "ID",
                type: "number",
                flex: 1,
                maxWidth: 75,
            },
            {
                field: "name",
                flex: 2,
                headerName: "Ad",
                width: 150
            },
            {
                field: "surname",
                flex: 2,
                headerName: "Soyad",
                width: 150
            },
            {
                field: "email",
                flex: 2,
                headerName: "E-mail",
                width: 150
            },
            {
                field: "subject",
                flex: 2,
                headerName: "Konu",
                width: 150
            },
            {
                field: "message",
                flex: 2,
                headerName: "Mesaj",
                width: 500,

            },
        ],
        [],
    );

    return (
        <>
            <List title={<Typography variant="h4">Gelen Mesajlar</Typography>}>
                <DataGrid {...dataGridProps} rows={messages ?? []} columns={columns} autoHeight />
            </List>

        </>
    )
}
