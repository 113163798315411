import {createTheme} from "@mui/material";
import {DefaultLightTheme, DefaultDarkTheme} from "./colors";
import {responsiveFontSizes} from "@pankod/refine-mui";

const LightTheme = createTheme({
    ...DefaultLightTheme,
    timeLine: {
        color: {
            pending: "#fff7e6",
            ready: "#e6fffb",
            cancelled: "#fff1f0",
            onTheWay: "#f6ffed",
        },
        dotColor: {
            pending: "#ffa940",
            ready: "#36cfc9",
            cancelled: "#ff4d4f",
            onTheWay: "#73d13d",
        },
    },
});

const DarkTheme = createTheme({
    ...DefaultDarkTheme,
    timeLine: {
        color: {
            pending: "#f2a400",
            ready: "#00c2a2",
            cancelled: "#c60d00",
            onTheWay: "#62c400",
        },
        dotColor: {
            pending: "#9f5700",
            ready: "#196966",
            cancelled: "#a60001",
            onTheWay: "#386d19",
        },
    },
});

const DarkThemeWithResponsiveFontSizes = responsiveFontSizes(DarkTheme);
const LightThemeWithResponsiveFontSizes = responsiveFontSizes(LightTheme);

export { LightThemeWithResponsiveFontSizes, DarkThemeWithResponsiveFontSizes };
