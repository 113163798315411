import React, { useState, useEffect, useContext } from "react";
import {
    AppBar,
    IconButton,
    Link,
    Avatar,
    Typography,
    TextField,
    Toolbar,
    Box,
    Autocomplete,
    Stack,
} from "@pankod/refine-mui";
import {
    useList,
    useGetIdentity,
} from "@pankod/refine-core";
import {
    SearchOutlined,
    DarkModeOutlined,
    LightModeOutlined,
} from "@mui/icons-material";

import { IOrder, IStore, ICourier } from "interfaces";
import { ColorModeContext } from "contexts";

interface IOptions {
    label: string;
    url: string;
    link: string;
    category: string;
}

export const Header: React.FC = () => {
    const [value, setValue] = useState("");
    const [options, setOptions] = useState<IOptions[]>([]);

    const { mode, setMode } = useContext(ColorModeContext);

    const { data: user } = useGetIdentity();


    const { refetch: refetchOrders } = useList<IOrder>({
        resource: "orders",
        config: {
            filters: [{ field: "q", operator: "contains", value }],
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const orderOptionGroup = data.data.map((item) => {
                    return {
                        label: `${item.store.title} / #${item.orderNumber}`,
                        url: "/images/default-order-img.png",
                        link: `/orders/show/${item.id}`,
                        category: "Yardımlar",
                    };
                });
                if (orderOptionGroup.length > 0) {
                    setOptions((prevOptions) => [
                        ...prevOptions,
                        ...orderOptionGroup,
                    ]);
                }
            },
        },
    });

    const { refetch: refetchStores } = useList<IStore>({
        resource: "stores",
        config: {
            filters: [{ field: "q", operator: "contains", value }],
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const storeOptionGroup = data.data.map((item) => {
                    return {
                        label: item.title,
                        url: "images/default-store-img.png",
                        link: `/stores/edit/${item.id}`,
                        category: "Şirketler",
                    };
                });
                setOptions((prevOptions) => [
                    ...prevOptions,
                    ...storeOptionGroup,
                ]);
            },
        },
    });

    const { refetch: refetchCouriers } = useList<ICourier>({
        resource: "couriers",
        config: {
            filters: [{ field: "q", operator: "contains", value }],
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const courierOptionGroup = data.data.map((item) => {
                    return {
                        label: `${item.name} ${item.surname}`,
                        url: item.avatar[0].url,
                        link: `/couriers/edit/${item.id}`,
                        category: "Çalışanlar",
                    };
                });
                setOptions((prevOptions) => [
                    ...prevOptions,
                    ...courierOptionGroup,
                ]);
            },
        },
    });

    useEffect(() => {
        setOptions([]);
        refetchOrders();
        refetchCouriers();
        refetchStores();
    }, [value]);

    return (
        <AppBar color="default" position="sticky" elevation={1}>
            <Toolbar>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                >

                    <Stack direction="row" alignItems="center" justifyContent="end" width="100%">
                        <IconButton
                            onClick={() => {
                                setMode();
                            }}
                        >
                            {mode === "dark" ? (
                                <LightModeOutlined />
                            ) : (
                                <DarkModeOutlined />
                            )}
                        </IconButton>
                        <Stack
                            direction="row"
                            gap="4px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="subtitle2">
                                {user?.name}
                            </Typography>
                            <Avatar src={user?.avatar} alt={user?.name} />
                        </Stack>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
