import React from "react";
import {IResourceComponentsProps, useDelete} from "@pankod/refine-core";
import {DataGrid,  GridActionsCellItem, GridColumns, List, Typography, useDataGrid} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";

export const FaqList: React.FC<IResourceComponentsProps> = () => {
    const {dataGridProps, tableQueryResult} = useDataGrid<any>();
    console.log("dataGridProps", dataGridProps, "table", tableQueryResult)
    // @ts-ignore
    const faqData = tableQueryResult?.data?.data?.data
    const { mutate: mutateDelete } = useDelete();

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "id",
                headerName: "ID",
                type: "number",
                flex: 1,
                maxWidth: 75,
            },
            {
                field: "question",
                flex: 2,
                headerName: "Soru",
                width: 450
            },
            {
                field: "answer",
                flex: 2,
                headerName: "Cevap",
                width: 500,

            },{
                field: "actions",
                flex: 1,
                headerName: "Seçenekler",
                type: "actions",
                width: 20,
                getActions: function render({ row }) {
                    return [
                        <GridActionsCellItem
                            key={2}
                            label="Sil"
                            icon={<Close color="error" />}
                            onClick={() => {
                                mutateDelete({
                                    resource: "faq",
                                    id: row.id,
                                });
                            }}
                            showInMenu
                        />,
                    ];
                },
            },
        ],
        [],
    );

    return (
        <>
            <List title={<Typography variant="h4">Sıkça Sorulan Sorular</Typography>}>
                <DataGrid {...dataGridProps} rows={faqData ?? []} columns={columns} autoHeight />
            </List>

        </>
    )
}
