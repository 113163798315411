import React, {useEffect, useState} from "react";
import {HttpError, useShow, useUpdate} from "@pankod/refine-core";
import {UseModalFormReturnType,} from "@pankod/refine-react-hook-form";
import {
    Autocomplete,
    Box,
    Button,
    Create,
    Drawer, Edit,
    FormControl,
    FormHelperText,
    FormLabel, IconButton,
    OutlinedInput,
    SaveButton,
    Stack, TextField,
    Typography
} from "@pankod/refine-mui";

import CloseIcon from "@mui/icons-material/Close";
import {IProduct} from "../../interfaces";

export const EditProduct = ({editDrawerFormProps, id}: {
    editDrawerFormProps:
        UseModalFormReturnType<IProduct, HttpError, IProduct, {}>; id: any
}) => {
    const {
        formState: {errors},
        refineCore: {onFinish},
        handleSubmit,
        modal: {visible, close},
    } = editDrawerFormProps;

    const [inputs, setInputs] = useState<any>({})

    const [features, setFeatures] = useState<any[]>([]);

    const handleChangeValue = (value: any, type: string) => {
        setInputs({...inputs, [type]: value})
    }
    const {queryResult} = useShow({resource: 'products', id: id})
    const data = queryResult?.data?.data?.data?.[0]


    const {mutate} = useUpdate();

    function useCreateMutate() {
        mutate({
                resource: "products",
                id: id,
                values: {
                    "image_url": inputs?.image_url,
                    "title": inputs?.title,
                    "description": inputs?.description,
                    "color": inputs?.color,
                    "price": inputs?.price,
                    "discounted_price": inputs?.discounted_price,
                    "stock": inputs?.stock,
                    "status": inputs?.status,
                    "brand": inputs?.brand,
                    "category": inputs?.category,
                    "special_category": inputs?.special_category,
                    "trendyol_link": inputs?.trendyol_link,
                    "n11_link": inputs?.n11_link,
                    "hepsiburada_link": inputs?.hepsiburada_link,

                },
            },
            {
                onError: (error, variables, context) => {
                    alert(error.message)

                },
                onSuccess: async (data, variables, context) => {
                    close();
                    setInputs({});
                    setFeatures([]);
                }
            });
    }

    function handleRemove(index: number) {
        setFeatures(features.filter((feature, key) => key !== index))
    }

    return (
        <Drawer
            sx={{zIndex: "1301"}}
            PaperProps={{sx: {width: {sm: "100%", md: 500}, height: '100%', overflow: 'scroll', '& .MuiPaper-root' :{overflow: 'scroll'} }}}
            open={visible}
            onClose={close}
            anchor="right"
        >

            <Edit
                resource="products"
                footerButtonProps={{sx: {display: 'none'}}}
            >
                <Stack>
                    <Box
                        paddingX="50px"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            paddingX: {
                                xs: 1,
                                md: 6,
                            },
                        }}
                    >
                        <form className="form" onSubmit={handleSubmit(onFinish)}>
                            <Stack gap="10px" marginTop="10px">

                                <FormControl>
                                    <FormLabel>
                                        Ürün Başlığı
                                    </FormLabel>
                                    <OutlinedInput
                                        id="title"
                                        placeholder={data?.title}
                                        value={inputs?.title}
                                        onChange={(e) => handleChangeValue(e.target.value, "title")}

                                        style={{height: "40px"}}
                                    />

                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Ürün Resim URL
                                    </FormLabel>
                                    <OutlinedInput
                                        id="image_url"
                                        value={inputs?.image_url}
                                        placeholder={data?.image_url}

                                        onChange={(e) => handleChangeValue(e.target.value, "image_url")}

                                        style={{height: "40px"}}
                                    />

                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Ürün Açıklaması
                                    </FormLabel>
                                    <OutlinedInput
                                        id="description"
                                        value={inputs?.description}
                                        placeholder={data?.description}

                                        onChange={(e) => handleChangeValue(e.target.value, "description")}
                                        style={{height: "40px"}}
                                    />

                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Ürün Rengi
                                    </FormLabel>
                                    <OutlinedInput
                                        id="color"
                                        value={inputs?.color}

                                        onChange={(e) => handleChangeValue(e.target.value, "color")}
                                        placeholder={data?.color}

                                        style={{height: "40px"}}
                                    />

                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Model
                                    </FormLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="brand"
                                        value={data?.brand}
                                        options={["ps3", "ps4", "ps5", "psvr", "xboxseriess", "xboxseriesx", "nintendo", "other"]}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, value) =>
                                            handleChangeValue(value, "brand")
                                        }
                                        sx={{height: "60px"}}
                                        placeholder={data?.brand}

                                        renderInput={(params) => <TextField {...params} label=""/>}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>
                                        Ürün Durumu
                                    </FormLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="status"
                                        value={data?.status}

                                        options={["zero", "secondhand", "other"]}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, value) =>
                                            handleChangeValue(value, "status")
                                        }
                                        sx={{height: "60px"}}
                                        placeholder={data?.status}

                                        renderInput={(params) => <TextField {...params} label=""/>}
                                    />

                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Kategori
                                    </FormLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="category"
                                        value={data?.category}

                                        options={["console", "controller", "game", "other"]}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, value) =>
                                            handleChangeValue(value, "category")
                                        }
                                        sx={{height: "60px"}}
                                        placeholder={data?.category}

                                        renderInput={(params) => <TextField {...params} label=""/>}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Özel Kategori
                                    </FormLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="special_category"
                                        value={data?.special_category}

                                        options={["top10", "discount", "featured", "new", "null"]}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, value) =>
                                            handleChangeValue(value, "special_category")
                                        }
                                        sx={{height: "60px"}}
                                        placeholder={data?.special_category}

                                        renderInput={(params) => <TextField {...params} label=""/>}
                                    />
                                </FormControl>
                                <FormControl>

                                    <FormLabel>
                                        İndirimsiz Fiyat
                                    </FormLabel>
                                    <OutlinedInput
                                        value={inputs?.discounted_price}
                                        placeholder={data?.discounted_price ?? "Ürün İndirimliyse Giriniz."}

                                        onChange={(e) => handleChangeValue(e.target.value, "discounted_price")}
                                        style={{height: "40px"}}

                                    />

                                </FormControl>
                                <FormControl>

                                    <FormLabel>
                                        Fiyat
                                    </FormLabel>
                                    <OutlinedInput
                                        value={inputs?.price}
                                        placeholder={data?.price}

                                        onChange={(e) => handleChangeValue(e.target.value, "price")}
                                        style={{height: "40px"}}

                                    />

                                </FormControl>
                                <FormControl>

                                    <FormLabel>
                                        Stok Adedi
                                    </FormLabel>
                                    <OutlinedInput
                                        value={inputs?.stock}
                                        placeholder={data?.stock}

                                        onChange={(e) => handleChangeValue(e.target.value, "stock")}
                                        style={{height: "40px"}}

                                    />

                                </FormControl>
                                <FormControl>

                                    <FormLabel>
                                        Trendyol Linki
                                    </FormLabel>
                                    <OutlinedInput
                                        value={inputs?.trendyol_link}
                                        placeholder={data?.trendyol_link}

                                        onChange={(e) => handleChangeValue(e.target.value, "trendyol_link")}
                                        style={{height: "40px"}}

                                    />

                                </FormControl>
                                <FormControl>

                                    <FormLabel>
                                        N11 Linki
                                    </FormLabel>
                                    <OutlinedInput
                                        value={inputs?.n11_link}
                                        placeholder={data?.n11_link}

                                        onChange={(e) => handleChangeValue(e.target.value, "n11_link")}
                                        style={{height: "40px"}}

                                    />

                                </FormControl>
                                <FormControl>

                                    <FormLabel>
                                        Hepsiburada Linki
                                    </FormLabel>
                                    <OutlinedInput
                                        value={inputs?.hepsiburada_link}
                                        placeholder={data?.hepsiburada_link}

                                        onChange={(e) => handleChangeValue(e.target.value, "hepsiburada_link")}
                                        style={{height: "40px"}}

                                    />

                                </FormControl>
                                <SaveButton onClick={() => {
                                    useCreateMutate();

                                }}/>
                            </Stack>
                        </form>
                    </Box>
                </Stack>
            </Edit>
        </Drawer>
    );
};
